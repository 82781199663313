import React, { useState } from 'react';
import { Card, CardContent, CardHeader, IconButton } from '@material-ui/core';
import { MdClear } from 'react-icons/md';

const VisitTypeInfos = ({ t, type, state, dispatch }) => {
  const onClose = () => {
    dispatch({ showVisibleInfos: false });
  };
  return (
    <Card
      style={{ display: !state.showVisibleInfos && 'none' }}
      className="VisitTypeInfos"
    >
      <div style={{ position: 'absolute', right: 10, top: 10 }}>
        <IconButton onClick={onClose}>
          <MdClear />
        </IconButton>
      </div>
      <CardHeader title={t(`visitTypeInfos.${type}.title`)} />
      <CardContent>
        <p>{t(`visitTypeInfos.${type}.description`)}</p>
        {/* <b>{t(`visitTypeInfos.${type}.description_2`)}</b>*/}
        <p style={{ whiteSpace: 'pre-line' }}>
          {t(`visitTypeInfos.note`)}
          {type == '17' ? t(`visitTypeInfos.note_pmr`) : ''}
        </p>
      </CardContent>
    </Card>
  );
};

export default VisitTypeInfos;
